import React from "react"
import Layout from "../components/layout"
import ddr3 from "../assets/pdf/ddr3-case-study.pdf"
import packageintegrator from "../assets/pdf/KNH_XPI_BOARDCENTRIC_092316a.pdf"

// markup
const LearningCenterPage = () => {
    return (
        <Layout title="Learning Center">
            <div className="mx-auto container px-4">
                <h1>Learning Center</h1>
                <hr className="mb-4" />
                <p>
                    Learn more about us with some of the following items below:
                </p>
                <ul>
                    <li>
                        <a href={ddr3} target="_blank">DDR3 Case Study</a>
                    </li>
                    <li>
                        <a href={packageintegrator} target="_blank">
                            Package Integrator & Sketch Router/Planner Board
                            Centric Process
                        </a>
                    </li>
                </ul>
            </div>
        </Layout>
    )
}

export default LearningCenterPage
